import type { ProgramWrapper } from "~/utils/program_wrapper";
import { getGrid } from "~/utils/helper_program";
import type { SearchParams } from "~/types/drupal_jsonapi";
import { createMemo } from "solid-js";

export default function CardSummary(props: {
  wrapper: ProgramWrapper;
  searchParams?: SearchParams;
}) {
  const grid = createMemo(() => getGrid(props.wrapper, props.searchParams));

  const lotsCount = createMemo(() =>
    grid().reduce((count, typology) => count + typology.lots.length, 0),
  );
  return (
    <>
      <div class="summary">
        <strong>
          {lotsCount()} offre{lotsCount() > 1 ? "s" : null}
        </strong>{" "}
        dans cette résidence
      </div>
    </>
  );
}
