import { createResource, Show, Suspense } from "solid-js";
import * as helper from "~/utils/helper_program";
import CTAs from "~/components/Cards/Components/CTAs";
import PreviewText from "~/components/Cards/Components/PreviewText";
import CardShell from "~/components/Cards/CardShell";
import VisualContainer from "~/components/Cards/Components/VisualContainer";
import CardSummary from "~/components/Cards/Components/CardSummary";
import { getWrapper } from "~/lib/fetcher";
import { isOutOfStock } from "~/utils/helper_program";

import "./Card.css";
import NouveauNeufLogos from "~/components/shared/NouveauNeufLogos";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import type { SearchParams } from "~/types/drupal_jsonapi";

export default function CardInfoWindow(props: {
  nid: number;
  searchParams?: SearchParams;
}) {
  const [wrapper] = createResource(() => props.nid, getWrapper);
  const settings = useDrupalSettingsContext();

  return (
    <>
      <Suspense fallback={<CardShell />}>
        <Show when={wrapper()}>
          <article
            class="node-program-card info-window"
            data-test={`card-program-${wrapper()!.program.field_program_id}`}
            data-ga-zone="card"
          >
            <VisualContainer wrapper={wrapper()!} />

            <div
              class="content-program search-map"
              classList={{
                "with-nouveau-neuf":
                  settings.nouveau_neuf_enabled &&
                  wrapper()!.program.field_segments_mkg &&
                  wrapper()!.program.field_segments_mkg!.length > 0,
              }}
            >
              <Show
                when={!helper.isPreview(wrapper()!)}
                fallback={<PreviewText type="info-window" />}
              >
                <Show when={!isOutOfStock(wrapper()!)}>
                  <CardSummary
                    wrapper={wrapper()!}
                    searchParams={props.searchParams}
                  />
                </Show>
              </Show>
              <Show
                when={
                  settings.nouveau_neuf_enabled &&
                  wrapper()!.program.field_segments_mkg &&
                  wrapper()!.program.field_segments_mkg!.length > 0
                }
              >
                <NouveauNeufLogos
                  zone="card"
                  segmentsMkg={wrapper()!.program.field_segments_mkg!}
                />
              </Show>
            </div>

            <CTAs wrapper={wrapper()!} />
          </article>
        </Show>
      </Suspense>
    </>
  );
}
